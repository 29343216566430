.Login {
    border: 2px solid #d3d3d3;
    border-radius: .5em;
    margin-bottom: 1em;
    margin-left: auto;
    margin-right: auto;
    margin-top: 250px;
    padding: 1em;
    text-align: left;
    width: 600px;
}

.h-login {
    text-align: center;
    margin-bottom: 30px;
}
   
.form {
    padding: 1em;
}

label {
    font-weight: 600;
}

.btn-right {
    float: right !important;
}

.btn-center {
    text-align: center;
    flex: 1;
}